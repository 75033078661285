<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

            <!-- Dashboard Box -->
        <b-card class="mb-0">
                <!-- Table Top -->
        <b-link class="brand-logo">
          <!-- <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            EL Taskforce
          </h2> -->

          <b-img
            :src="appLogoImage"
            alt="logo"
            style="width:240px"
          />

        </b-link>
        <b-card-title class="mb-1">
          Password Reset Form
        </b-card-title>
          <b-form
            class="auth-login-form mt-2"
          >
        <b-row>

                      <b-col cols="12" md="12">
                        <b-form-group label="Email Address" class="" label-for="email">
                            <b-form-input placeholder="Enter Email Address" type="email" v-model="email" class="form-control-merge" autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row>

            <b-col cols="12" sm="12" class="d-flex align-items-center justify-content-center">
        <b-button variant="warning" class="mt-1" @click="resetPassword">
            <span class="text-nowrap">Submit</span> 
        </b-button>
            </b-col>
                </b-row>

<br>
            <b-form-group class="d-flex justify-content-between">
                <div>
            <span>Click here to return to<b-link href="" :to="{name: 'login'}" > Log In</b-link></span>
                </div>
            
            </b-form-group>

          </b-form>

        </b-card>            
    </div>
  </div>
</template>

<script>
import {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BAlert, BImg, BCardTitle
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment";
import Bus from "../../../event-bus";

export default {
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BAlert, BImg, BCardTitle
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            appLogoImage : require('@/assets/images/logo/task-force-full.png'),
            webUrl: process.env.VUE_APP_SERVER_URL,
            error_message: null, 
            showDismissibleAlert:false,

            token: this.$route.params.token,
            username: '',
            new_password: '',
            repeat_new_password: '',
            link_error:'',
        }
    },
    computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
    methods : {
        resetPassword() {
                this.$store.dispatch(POST_API, {
                    data:{
                        email: this.email,
                    },
                    api:'/api/forgot-password'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {                    
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Password reset will be sent to your email shortly',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            window.location.replace('/login');
                        });
                    }        
                }); 
        },
       
    },
    
    mounted(){
    }
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
